<template>
  <div class="partyBuilding">
    <div class="partyBuilding-t">
      <div class="banner" @click="toBanner">
        <img src="./img/banner.png" alt="" />
      </div>
      <div class="iconList">
        <div class="item te" @click="toPartyMember">
          <div class="item-t"><img src="./img/partyMem.png" alt="" /></div>
          <div class="item-b">亮相台</div>
        </div>
        <div class="item" @click="toPartyAffairs">
          <div class="item-t"><img src="./img/partyInfo.png" alt="" /></div>
          <div class="item-b">党务通</div>
        </div>
        <div class="item" @click="toRedHousekeeper">
          <div class="item-t"><img src="./img/guanjia.png" alt="" /></div>
          <div class="item-b">红管家</div>
        </div>
        <div class="item" @click="toSpeak">
          <div class="item-t"><img src="./img/speak.png" alt="" /></div>
          <div class="item-b">码上说</div>
        </div>
      </div>
    </div>
    <div class="classListBanner">
      <img src="./img/classListBanner.png" @click="toClassScheduleCard()" />
    </div>
    <div class="partyBuilding-title">三会一课</div>
    <div class="partyBuilding-main">
      <div
        class="item"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="title">
            {{ item.topicTitle }}
          </div>
          <div class="item-lb">
            <div class="item-lbl">{{ item.createTime }}</div>
            <div class="item-lbr">
              <div class="img"><img src="./img/look.png" alt="" /></div>
              <div class="txt">{{ item.readCount }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img :src="item.topicPicture" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "partyBuilding",
  data() {
    return {
      fromFlag: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      pointsData: {
        totlePointCount: 0,
      },
      curPage: 1,
      pageSize: 5,
      listData: [],
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
  },
  mounted() {
    this.getPartyList();
  },
  methods: {
    toClassScheduleCard() {
      this.$router.push({
        name: "grassRootsPartySchool",
        query: {
          fromFlag: this.fromFlag,
        },
      });
    },
    toBanner() {
      this.$router.push({
        name: "partyNotice",
      });
    },
    toRedHousekeeper() {
      this.$router.push({
        name: "redHousekeeper",
        query: {
          fromFlag: this.fromFlag,
        },
      });
    },
    toSpeak() {
      window.location.href = "https://future.zhuneng.cn/QRCodeMqtb/";
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (this.fromFlag == "dingding") {
        this.$router.push({
          name: "partyTopic",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/partyInfoDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      }
    },
    toPartyAffairs() {
      this.$router.push({
        name: "partyAffairs",
        query: {
          fromFlag: this.fromFlag,
        },
      });
    },
    toPartyMember() {
      this.$router.push({
        name: "partyMember",
      });
    },
    async getPartyList() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicTypes: "7,8,9,10,11", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.curPage++,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        // if (this.curPage >= res.data.pages) {
        //   this.finished = true;
        // } else {
        //   this.finished = false;
        //   this.$refs.list.loading = false;
        // }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.partyBuilding {
  min-height: 100vh;
}
.partyBuilding {
  .partyBuilding-t {
    padding: 30px 20px 0 20px;
    .banner {
      height: 292px;
      img {
        height: 100%;
      }
    }
    .iconList {
      display: flex;
      padding: 28px 20px 38px;
      align-items: center;
      justify-content: space-between;
      .item-t {
        width: 92px;
        height: 98px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        font-size: 28px;
        margin: 6px auto;
        font-weight: 600;
        color: #1a1c34;
        line-height: 40px;
      }
      .te {
        .item-t {
          width: 76px;
          height: 104px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .classListBanner {
    box-sizing: border-box;
    width: 100%;
    height: 176px;
    padding: 0 30px;
    img {
      width: 690px;
      height: 100%;
    }
  }
  .partyBuilding-title {
    padding: 40px 0px 2px 28px;
    font-size: 36px;
    font-weight: 600;
    color: #1a1c34;
    line-height: 50px;
  }
  .partyBuilding-main {
    .item {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        margin-right: 38px;
        .title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 44px;
          margin-bottom: 28px;
        }
        .item-lb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-lbl {
            font-size: 24px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 34px;
          }
          .item-lbr {
            display: flex;
            align-items: center;
            .img {
              width: 28px;
              height: 26px;
              margin-right: 4px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 24px;
              font-weight: 400;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
